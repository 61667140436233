import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeck } from '../../redux/actions';
import './DeckList.scss';
import PictureButton from '../Lobby/PictureButton';

const DeckList = ({ decks, onDeckSelected }) => {

    const { selectedDeck } = useSelector((state) => ({
        selectedDeck: state.cards.selectedDeck
    }));

    const dispatch = useDispatch();

    const doClick = (event, deck) => {
        dispatch(selectDeck(deck));
        onDeckSelected && onDeckSelected(deck);
    };

    return (
        <div className='deck-list banner-buttons'>
            {!decks || decks.length === 0 ? (
                <div>No decks found.</div>
            ) : (
                decks?.map((deck) => {
                    const imgClass = deck.name.replace(' ', '-').toLowerCase();
                    return (
                        <PictureButton
                            key={deck}
                            imageClass={imgClass}
                            showText={false}
                            onClick={(event) => doClick(event, deck)}
                        />
                    );
                })
            )}
        </div>
    );
};

DeckList.displayName = 'DeckList';
export default DeckList;
